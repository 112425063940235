import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowToTop from "../ScrollTopArrow";
import "../../styles/app.css";
import NavLinks from "./navlinks";
import M from "materialize-css";
import logo from "../../images/replacement-metal-castings.png";
import logoMobile from "../../images/replacement-metal-castings-mobile.png";
import logoNavSide from "../../images/replacement-metal-castings-navside.png";
import makeInUsa from "../../images/make-in-usa.png";
// import Hamburger from 'react-hamburgers';
import "./hamburgers/hamburgers.scss";
import "../../styles/dropdown.css";

const topStyle = {
	position: "absolute",
	border: "0",
	boxShadow: "0 0 0",
};

// const SabreHamberger = () => {
// 	const [active, setActive] = useState(false);

// 	return (
// 		<div className="nav-wrapper">
// 			<Hamburger
// 				active={active}
// 				type="stand"
// 				onClick={() => setActive(!active)}
// 			/>
// 			{/* {active && (
// 				<>
// 					<ul className="dropLocation">
// 						<li className="menu-item">
// 							<ul className="sub-menu">
// 								<li className="menu-item sub-menu-item">
// 									<Link onClick={() => setActive(!active)} to="/Clients-Issues">
// 										Clients & Issues
// 									</Link>
// 								</li>
// 								<li className="menu-item sub-menu-item">
// 									<Link onClick={() => setActive(!active)} to="/Contact">
// 										Contact
// 									</Link>
// 								</li>
// 							</ul>
// 						</li>
// 					</ul>
// 				</>
// 			)} */}
// 		</div>
// 	);
// };

const Home = (props) => {
	useEffect(() => {
		M.Sidenav.init(document.getElementById("mobile-nav"));
		const elems = document.querySelectorAll(".collapsible");
		M.Collapsible.init(elems, {
			accordion: false,
			onOpenStart: function (e) {
				e.querySelector(".material-icons").innerHTML = "remove";
			},
			onCloseStart: function (e) {
				e.querySelector(".material-icons").innerHTML = "add";
			},
		});
	}, []);

	const closeMobileNav = () => {
		let elem = document.getElementById("mobile-nav");
		let instance = M.Sidenav.getInstance(elem);
		instance.close();
	};

	//   const [state, setState] = React.useState('active');

	//   const { path } = props;

	return (
		<div>
			<div className="container headerBox">
				<div className="nav-wrapper">
					<header
						className="row"
						style={{ position: "relative", zIndex: 100, margin: "0" }}
					>
						<nav className="transparent" style={topStyle}>
							<a
								href="#!"
								data-target="mobile-nav"
								className="sidenav-trigger center show-on-medium-and-down"
							>
								<i className="material-icons">menu</i>
							</a>
							{/* <a
								href="#!"
								data-target="mobile-nav"
								className="sidenav-trigger center show-on-medium-and-down"
							>
								<SabreHamberger />
							</a> */}
							<Link className="" to="/">
								<img src={logo} alt="" className="logoAdj hide-on-small-only" />
							</Link>
							<Link className="" to="/">
								<img
									src={logoMobile}
									alt=""
									className="logoAdj show-on-small"
								/>
							</Link>
						</nav>
						<img
							src={makeInUsa}
							alt=""
							className="right"
							style={{ marginTop: "25px" }}
						/>
						<nav className="transparent" style={topStyle}>
							<ul
								className="right hide-on-med-and-down"
								style={{ margin: "100px 0px 0 0" }}
							>
								<NavLinks closeMobileNav={closeMobileNav} />
							</ul>
						</nav>
					</header>
				</div>
			</div>
			<ul className="sidenav uppercaseTxt themeBgColor" id="mobile-nav">
				<li style={{ height: "130px" }}>
					<Link
						className=""
						to="/"
						style={{ border: "0" }}
						onClick={() => closeMobileNav()}
					>
						<img
							src={logoNavSide}
							alt=""
							className="responsive-img"
							style={{ marginTop: "15px" }}
						/>
					</Link>
				</li>
				<NavLinks closeMobileNav={closeMobileNav} />
			</ul>

			<ArrowToTop />
		</div>
	);
};

export default React.memo(Home);

import React, { memo, useState, useRef } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import M from 'materialize-css';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import { API_URL } from '../../config';

const URL = 'http://develop-replacementmetalcastings-com.janus-thesabresolution-com.vps.ezhostingserver.com/';

console.log({ URL });
// const API_URL = URL + 'cfc/api/';

const SimpleInputContainer = memo(
  ({ register, errors, className, name, label, required, formState: { isDirty }, children, ...rest }) => {
    return (
      <div className={className}>
        <input type="text" name={name} id={name} ref={register(required && required)} />
        <label htmlFor={name}>{label}</label>
        {children}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty && prevProps.children === nextProps.children
);

const CustomRadioContainer = memo(
  ({ register, errors, className, name, label, options, required, formState: { isDirty }, children, ...rest }) => {
    return (
      <div className={className}>
        <p style={{ fontSize: '16px', margin: '0' }}>
          {label}
          <br />
          {options.map((el, i) => (
            <label key={i}>
              <input ref={register} id={`${name}_${i}`} type="radio" name={name} value={el.value} />
              <span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>{el.label}</span>
            </label>
          ))}
        </p>
        {children}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty
);

const CustomCheckboxContainer = memo(
  ({ register, errors, className, name, label, options, required, formState: { isDirty }, children, ...rest }) => {
    return (
      <div className={className}>
        <p style={{ fontSize: '16px', margin: '0' }}>
          {label}
          <br />
          {options.map((el, i) => (
            <label key={i}>
              <input ref={register} id={`${name}_${i}_${Math.floor(Math.random() * 999)}`} type="checkbox" name={name} value={el.value} />
              <span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>{el.label}</span>
            </label>
          ))}
        </p>
        {children}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty
);

const CustomMultiSelectContainer = memo(
  ({ register, setValue, trigger, unregister, errors, className, name, label, options, required, formState: { isDirty }, children, ...rest }) => {
    const selectEl = useRef(null);
    const [select, setSelect] = useState(null);

    React.useEffect(() => {
      try {
        setSelect(M.FormSelect.init(selectEl.current, {}));
      } catch (e) {}
    }, [selectEl, options]);

    React.useEffect(() => {
      register(name, required);
      return () => {
        unregister(name);
      };
    }, []);

    const handleChange = (v) => {
      setValue(name, select.getSelectedValues());
      trigger();
    };

    const handleSetRef = (ref) => {
      selectEl.current = ref;
    };

    return (
      <div className={`input-field ${className}`}>
        <select name={name} ref={handleSetRef} multiple onChange={handleChange}>
          <option disabled value="">
            Select {name.substring(0, name.length - 1)}({name.charAt(name.length - 1)})
          </option>
          {options.map((opt, x) => (
            <option key={x} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
        <label>{label}</label>
        {children}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty && prevProps.children === nextProps.children
);

const SimpleTextAreaContainer = memo(
  ({ register, errors, className, name, label, options, required, formState: { isDirty }, children, ...rest }) => {
    return (
      <div className={className}>
        <textarea className="materialize-textarea" name={name} style={{ height: '100px' }} ref={register}></textarea>
        <label>{label}</label>
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty
);

const PersonalInformation = ({ children, job, ...rest }) => {
  const methods = useFormContext();
  const { errors } = methods;

  return (
    <>
      <div className="card">
        <div className="card-content">
          <h4 className=" robotoSlab" style={{ color: 'black', margin: '0' }}>
            POSITION : {job.title}
          </h4>
          <fieldset>
            <legend>Personal Information</legend>
            <div className="row">
              <SimpleInputContainer {...methods} className="input-field col s12 m6 l6" label="Name (last, first, middle) *" name="fullname" required={{ required: 'Your name is required' }}>
                <>{errors && errors.fullname && <span className="red-text helper-text">{errors.fullname.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m6 l6" label="Street Address and/or Mailing Address *" name="address" required={{ required: 'Your address is required' }}>
                <>{errors && errors.address && <span className="red-text helper-text">{errors.address.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="City *" name="city" required={{ required: 'City is required' }}>
                <>{errors && errors.city && <span className="red-text helper-text">{errors.city.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="State *" name="state" required={{ required: 'State is required' }}>
                <>{errors && errors.state && <span className="red-text helper-text">{errors.state.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Zip *" name="zip" required={{ required: 'Zip is required' }}>
                <>{errors && errors.zip && <span className="red-text helper-text">{errors.zip.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Home Telephone Number" name="homephone" required={{ required: 'Phone is required' }}>
                <>{errors && errors.homephone && <span className="red-text helper-text">{errors.homephone.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Business Telephone Number" name="businessphone" required={{ required: 'Phone is required' }}>
                <>{errors && errors.businessphone && <span className="red-text helper-text">{errors.businessphone.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Cellular Telephone Number" name="cellphone" required={{ required: 'Phone is required' }}>
                <>{errors && errors.cellphone && <span className="red-text helper-text">{errors.cellphone.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Date you can start work" name="start" required={{ required: 'Phone is required' }}>
                <>{errors && errors.start && <span className="red-text helper-text">{errors.start.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Salary Desired" name="salary" required={{ required: 'Salary Desired is required' }}>
                <>{errors && errors.salary && <span className="red-text helper-text">{errors.salary.message}</span>}</>
              </SimpleInputContainer>
              <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Past Military Service" name="past_military_service" required={{ required: 'Past Military Service is required' }}>
                <>{errors && errors.past_military_service && <span className="red-text helper-text">{errors.past_military_service.message}</span>}</>
              </SimpleInputContainer>
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12 m6 l6"
                label="Are you at least 18 years of age or older? *"
                name="year_old"
                required={{
                  required: 'Are you at least 18 years of age or older is required',
                }}
              >
                <>{errors && errors.year_old && <span className="red-text helper-text">{errors.year_old.message}</span>}</>
              </CustomRadioContainer>
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12 m6 l6"
                label="Do you have a High School Diploma or GED? *"
                name="hsd"
                required={{ required: 'Did you graduate is required' }}
              >
                <>{errors && errors.hsd && <span className="red-text helper-text">{errors.hsd.message}</span>}</>
              </CustomRadioContainer>
            </div>
          </fieldset>
          <br />
          <fieldset>
            <legend>Position Information</legend>
            <div className="row">
              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>Check all that you are willing to work</p>
              </div>
              <CustomCheckboxContainer
                options={[
                  { value: 'fulltime', label: 'Full Time' },
                  { value: 'parttime', label: 'Part Time' },
                ]}
                {...methods}
                className="input-field col s12 m3 l3"
                label="Hours:"
                name="checkboxes"
              />
              <CustomCheckboxContainer
                options={[
                  { value: 'days', label: 'Days' },
                  { value: 'evenings', label: 'Evenings' },
                ]}
                {...methods}
                className="input-field col s12 m3 l3"
                label="Shift:"
                name="checkboxes"
              />
              <CustomCheckboxContainer
                options={[
                  { value: 'swing', label: 'Swing' },
                  { value: 'graveyard', label: 'Graveyard' },
                  { value: 'weekends', label: 'Weekends' },
                ]}
                {...methods}
                className="input-field col s12 m3 l3"
                label="Extra Shift:"
                name="checkboxes"
              />
              <CustomCheckboxContainer
                options={[
                  { value: 'Regular', label: 'Regular' },
                  { value: 'Temporary', label: 'Temporary' },
                ]}
                {...methods}
                className="input-field col s12 m3 l3"
                label="Status:"
                name="checkboxes"
              />
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12 m12 l12"
                label="Are you authorized to work in the U.S. on an unrestricted basis?"
                name="us_authorized"
                required={{ required: 'The question is required to answer' }}
              >
                <>{errors && errors.us_authorized && <span className="red-text helper-text">{errors.us_authorized.message}</span>}</>
              </CustomRadioContainer>
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12 m12 l12"
                label="Have you ever been convicted of a felony? (Convictions will not necessarily disqualify an applicant for employment.)"
                name="felony"
                required={{ required: 'The question is required to answer' }}
              >
                <>{errors && errors.felony && <span className="red-text helper-text">{errors.felony.message}</span>}</>
              </CustomRadioContainer>
              <SimpleInputContainer {...methods} className="input-field col s12" label="If yes, explain:" name="felony_explained">
                <>{errors && errors.felony_explained && <span className="red-text helper-text">{errors.felony_explained.message}</span>}</>
              </SimpleInputContainer>
              {/* <CustomRadioContainer
									options={[
										{ value: "YES", label: "Yes" },
										{ value: "NO", label: "No" },
									]}
									{...methods}
									className="input-field col s12 m12 l12"
									label="Have you been told the essential functions of the job or have you been viewed a copy of the job description listing the essential functions of the job?"
									name="informed"
									required={{ required: "The question is required to answer" }}
								>
									<>
										{errors && errors.informed && (
											<span className="red-text helper-text">
												{errors.informed.message}
											</span>
										)}
									</>
								</CustomRadioContainer> */}
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                ]}
                {...methods}
                className="input-field col s12 m12 l12"
                label="Can you perform these essential functions of the job without reasonable accommodation?"
                name="can_perform"
                required={{ required: 'The question is required to answer' }}
              >
                <>{errors && errors.can_perform && <span className="red-text helper-text">{errors.can_perform.message}</span>}</>
              </CustomRadioContainer>
              <SimpleInputContainer {...methods} className="input-field col s12" label="If no, please list the accommodations you would require to perform the essential functions of the job:" name="require_perform_job">
                <>{errors && errors.require_perform_job && <span className="red-text helper-text">{errors.require_perform_job.message}</span>}</>
              </SimpleInputContainer>
            </div>
          </fieldset>
          <br />
          <fieldset>
            <legend>Qualifications</legend>
            <div className="row">
              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>
                  Please list any education or training you feel relates to the position applied for that would help you perform the work, such as schools, colleges, degrees, vocational or technical programs, and military training.
                </p>
              </div>
              {[1, 2, 3].map((el, i) => (
                <div key={i}>
                  <SimpleInputContainer {...methods} className="input-field col s12 m3 l3" label="School Name" name={`school[${i}].name`} />

                  <SimpleInputContainer {...methods} className="input-field col s12 m3 l3" label="Degree" name={`school[${i}].degree`} />

                  <SimpleInputContainer {...methods} className="input-field col s12 m6 l6" label="Address/City/State" name={`school[${i}].location`} />
                </div>
              ))}
            </div>
          </fieldset>
          <br />
          <fieldset>
            <legend>Special Skills</legend>
            <div className="row">
              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>List any special skills or experience that you feel would help you in the position that you are applying for (leadership, organizations/teams, etc.</p>
              </div>
              <SimpleTextAreaContainer {...methods} className="input-field col s12 m12 l12" label="Special Skills" name="skills">
                <>{errors && errors.college && <span className="red-text helper-text">{errors.skills.message}</span>}</>
              </SimpleTextAreaContainer>
            </div>
          </fieldset>
          <br />
          <fieldset>
            <legend>References</legend>
            <div className="row">
              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>
                  Please list three professional references not related to you, with full name, address, phone number, and relationship. If you don’t have three professional references, then list personal, unrelated references.
                </p>
              </div>
              {[1, 2, 3].map((el, i) => (
                <div key={i}>
                  <SimpleInputContainer {...methods} className="input-field col s12 m3 l3" label="Name" name={`reference[${i}].name`} />

                  <SimpleInputContainer {...methods} className="input-field col s12 m5 l5" label="Address/City/State" name={`reference[${i}].location`} />

                  <SimpleInputContainer {...methods} className="input-field col s12 m2 l2" label="Phone" name={`reference[${i}].phone`} />

                  <SimpleInputContainer {...methods} className="input-field col s12 m2 l2" label="Relationship" name={`reference[${i}].relationship`} />
                </div>
              ))}
            </div>
          </fieldset>
          <br />
          <fieldset>
            <legend>Work History</legend>
            <div className="row">
              <div className="input-field col s12 m12 l12">
                <p style={{ fontSize: '16px', margin: '0' }}>Start with your present or most recent employment and work back. (INCLUDE PAID AND UNPAID POSITIONS)</p>
              </div>
              <CustomRadioContainer
                options={[
                  { value: 'YES', label: 'Yes' },
                  { value: 'NO', label: 'No' },
                  { value: 'N/A', label: 'N/A' },
                ]}
                {...methods}
                className="input-field col s12 m12 l12"
                label="May we contact your present employer?"
                name="contact_present"
                required={{ required: 'The question is required to answer' }}
              >
                <>{errors && errors.contact_present && <span className="red-text helper-text">{errors.contact_present.message}</span>}</>
              </CustomRadioContainer>
              <div className="clearfix"></div>
              {[1, 2, 3].map((el, i) => (
                <div key={i} style={{ borderBottom: i < 2 ? '1px dashed grey' : '' }}>
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Job Title" name={`employment[${i}].title`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Start Date" name={`employment[${i}].start`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="End Date" name={`employment[${i}].end`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Company Name" name={`employment[${i}].company`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Supervisor's Name" name={`employment[${i}].supervisor`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Phone Number" name={`employment[${i}].phone`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="City" name={`employment[${i}].city`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="State" name={`employment[${i}].state`} />
                  <SimpleInputContainer {...methods} className="input-field col s12 m4 l4" label="Zip" name={`employment[${i}].zip`} />

                  <SimpleInputContainer {...methods} className="input-field col s12 m12 l12" label="Duties" name={`employment[${i}].duties`} />

                  <SimpleInputContainer {...methods} className="input-field col s12 m12 l12" label="Reason for Leaving" name={`employment[${i}].reason`} />
                  <div className="clearfix"></div>
                  <br />
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>
      <div className="clearfix"></div>
    </>
  );
};

const FileUploader = ({ files, setFiles }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setLoading] = useState(false);

  const handleOnChange = React.useCallback(
    (e) => {
      if (!executeRecaptcha) return;
      if (isLoading) return;

      e.persist();

      setLoading(true);
      (async () => {
        try {
          const token = await executeRecaptcha('fileUpload');
          const data = new FormData();

          console.log({ e });
          data.append('file', e.target.files[0]);
          data.append('token', token);

          axios({
            url: `${API_URL}jobs.cfc?method=upload`,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data,
          })
            .then((r) => r.status === 200 && r.data)
            .then((d) => {
              if (d.success) {
                setFiles([...files, `${URL}assets/files/${d.link}`]);
                M.toast({
                  html: 'File uploaded successfully' || d.messages[0],
                });
              } else {
                d.messages.map((e) => {
                  M.toast({ html: e });
                });
              }
            })
            .finally((f) => {
              setLoading(false);
              document.getElementById('display_fn').value = '';
              document.getElementById('file').value = '';
            })
            .catch((e) => console.error({ e }));
        } catch (e) {
          console.error({ e });
        }
      })();
    },
    [executeRecaptcha, isLoading]
  );

  return (
    <div className="col s12 m12 l10 offset-l1">
      <div className="card">
        <div className="card-content">
          <fieldset>
            <legend>FILE UPLOADS</legend>
            <div className="row">
              <div className="col s12">
                <p>You can upload your resume/cover letter PDF documents here</p>
              </div>
              {files.length > 0 && (
                <>
                  <div className="collection">
                    {files.map((e, i) => (
                      <a style={{ color: 'black' }} target="_blank" rel="noopener noreferrer" className="collection-item" rel="" key={i} href={e}>
                        {e.split('/')[e.split('/').length - 1]}
                      </a>
                    ))}
                  </div>
                </>
              )}
              <div className="col s12">
                <div className="file-field input-field">
                  <div className="btn">
                    <span>File</span>
                    <input id="file" name="file" type="file" onChange={handleOnChange} accept="application/pdf" />
                  </div>
                  <div className="file-path-wrapper">
                    <input id="display_fn" className="file-path validate" type="text" />
                  </div>
                </div>
                {isLoading && (
                  <div className="progress">
                    <div className="indeterminate"></div>
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

const FormWrapper = ({ job }) => {
  const methods = useForm({ defaultValues: { temp_perm: ['', '', ''] } });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const sanitize = (v) => {
    Object.keys(v).forEach((key) => {
      if (v[key] == null) {
        v[key] = '';
      }
    });
  };

  const onSubmit = React.useCallback(
    (v) => {
      if (!executeRecaptcha) return;
      if (loading) return;

      setLoading(true);
      sanitize(v);

      const checkboxes = ['fulltime', 'parttime', 'days', 'evenings', 'swing', 'graveyard', 'weekends', 'regular', 'temporary'];

      checkboxes.map((x, i) => {
        v[x] = v.checkboxes.indexOf(x) > -1 ? 'Yes' : 'No';
      });

      console.log({ v });

      (async () => {
        try {
          const token = await executeRecaptcha('newApplication');

          console.log({ token });

          const data = {
            ...v,
            files,
            token,
            job: job.title,
          };

          axios({
            url: `${API_URL}jobs.cfc?method=process`,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data,
          })
            .then((r) => r.status === 200 && r.data)
            .then((d) => {
              console.log({ d });
              if (d.success) {
                M.toast({
                  html: 'We have received your application. Thank you!',
                });
                document.getElementById('app_form').reset();
                setFiles([]);
                methods.reset();
              } else {
                d.messages.map((e) => {
                  M.toast({ html: e });
                });
              }
            })
            .catch((e) => console.error({ e }))
            .finally((f) => setLoading(false));
        } catch (e) {
          console.error('e here', { job }, { e });
          setLoading(false);
        }
      })();
    },
    [executeRecaptcha, files, loading, job]
  );

  return (
    <FormProvider {...methods}>
      <form id="app_form" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="col s12 m12 l10 offset-l1">
          <br />
          <h1 className="abstractTextColor robotoSlab" style={{ margin: '0' }}>
            EMPLOYMENT APPLICATION
          </h1>

          <p>
            <i>It is our policy to comply with all applicable state and federal laws prohibiting discrimination in employment based on race, age, color, sex, religion, national origin, disability or other protected classifications.</i>
          </p>
          <p>
            <b>Please carefully read and answer ALL questions.</b> You will not be considered for employment if you fail to completely answer all the questions on this application. You may attach a résumé, but all questions must be answered.
          </p>
          {job && <PersonalInformation job={job} />}
        </div>
        {/* <StandardInterviewForm />
				<JobDescriptionEssentialFunctions /> */}
        {job && (
          <>
            <FileUploader {...{ files, setFiles }} />

            <div className="col s12 m12 l10 offset-l1">
              <p style={{ marginTop: '0' }}>
                I certify that the facts set forth in this Application for Employment are true and complete to the best of my knowledge. I understand that if I am employed, false statements, omissions or misrepresentations may result in my dismissal.
                I authorize the Employer to make an investigation of any of the facts set forth in this application and release the Employer from any liability. The employer may contact any listed references on this application.
              </p>
              <p>
                I acknowledge and understand that the company is an “at will” employer. Therefore, any employee (regular, temporary, or other type of category employee) may resign at any time, just as the employer may terminate the employment
                relationship with any employee at any time, with or without cause, with or without notice to the other party.
              </p>
            </div>

            <div className="clearfix"></div>
            <br />
            <br />
            <div className="row">
              <div className="center-align col s12">
                {loading ? (
                  <button disabled className="waves-effect waves-light btn">
                    Submit Application
                  </button>
                ) : (
                  <button className="waves-effect waves-light btn">Submit Application</button>
                )}
              </div>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default FormWrapper;

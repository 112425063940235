import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import M from 'materialize-css';
import { AuthProvider } from '../../context/auth-provider';
import { AdminArea } from './admin';
import { API_URL } from '../../config';

import './style.css';

const JobIndexItem = ({ job, setJob }) => {
  const history = useHistory();

  useEffect(() => {
    // const elems = document.querySelectorAll('.collapsible');
    // M.Collapsible.init(elems, {
    //   accordion: false,
    //   onOpenStart: function (e) {
    //     e.querySelector('.material-icons').innerHTML = 'remove';
    //   },
    //   onCloseStart: function (e) {
    //     e.querySelector('.material-icons').innerHTML = 'add';
    //   },
    // });
  }, []);

  const cbody = {
    borderBottom: '1px solid #ddd',
    boxSizing: 'border-box',
    padding: '2rem',
  };
  return (
    <div style={{ cursor: 'pointer' }} className="col s12">
      <ul className="collapsibles z-depth-5 themeBgColor">
        <li className="">
          <div
            className="collapsible-header themeBgColor abstractTextColor"
            style={{ display: 'block' }}
          >
            <h1 className="jobTitle robotoSlab" style={{ margin: '0' }}>
              <div style={{ width: '10%' }}>
                {/* <i className="material-icons" style={{ margin: '0' }}>
                  add
                </i> */}
              </div>
              <div style={{ width: '90%' }}>{job.title}</div>
            </h1>
            <div
              className="themeTextColor"
              style={{ fontSize: '16px' }}
              dangerouslySetInnerHTML={{
                __html: job.description.replace(/\r\n|\r|\n/g, '<br />'),
              }}
            />
          </div>
          <div className="collapsible-bodyx" style={cbody}>
            <ul style={{ paddingLeft: '20px' }}>
              {job.requirements.map((x, i) => (
                <li
                  key={x.id}
                  style={{ listStyleType: 'disc', fontSize: '16px' }}
                >
                  {x.description}
                </li>
              ))}
            </ul>
            <p>
              <Link to={`/apply/${job.id}`} className="btn">
                Apply now
              </Link>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

const JobListings = ({ jobs, setJob }) => {
  return (
    <>
      {jobs.length === 0 && (
        <h1
          className="center-align abstractTextColor robotoSlab"
          style={{ marginTop: '0' }}
        >
          No Jobs Available
        </h1>
      )}
      {jobs.map((el, i) => (
        <JobIndexItem key={i} job={el} setJob={setJob} />
      ))}
    </>
  );
};

const JobListingsWrapper = ({ jobs }) => {
  const [job, setJob] = useState(null);

  return <>{!job && <JobListings jobs={jobs} setJob={setJob} />}</>;
};

const JobsWrapper = ({ location, ...rest }) => {
  const showAdmin = location.pathname.indexOf('/admin') > -1;

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const get = async () => {
      axios
        .get(`${API_URL}jobs.cfc?method=getJobs`)
        .then((r) => r.status === 200 && r.data)
        .then((d) => d.length && setJobs(d))
        .catch((e) => new Error('error'));
    };

    get();
  }, []);

  return (
    <AuthProvider>
      <div
        className="themeTextColor"
        style={{ backgroundColor: '#3d3d3d', paddingBottom: '50px' }}
      >
        <div className="container row">
          {showAdmin && (
            <>
              <AdminArea jobs={jobs} setJobs={setJobs} />
              <hr />
            </>
          )}
          <div className="jobStyle flexAdj">
            <div className="col s12 z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0', textTransform: 'uppercase' }}
              >
                Join Our Team
              </h1>
              <p>
                At Replacement Metal Castings, we melt iron and steel to produce
                high-quality, engineered castings. We're continually raising our
                quality standards through production control and process
                repeatability. With investments in state-of-the-art equipment,
                technology and education, our family of employees work
                passionately each day to meet each customer's specifications
                Replacement Metal Castings is one of the safest foundries that
                you'll ever step foot in. Come to work with us and be a part of
                the family here! Replacement Metal Castings is family owned and
                operated with solid values and core expectations.  
              </p>
              <p>
                Full benefit package available, including: health, dental, life,
                short-term and long-term disability insurances. 401k with
                company match. Paid vacation and holidays. (Dental, vision, and
                life insurance company supplied)
              </p>
              <p>
                We are an equal opportunity employer, and all qualified
                applicants will receive consideration for employment without
                regard to race, color, religion, sex, national origin,
                disability status, protected veteran status, or any other
                characteristic protected by law.
              </p>
              <p>
                Join our family-owned and operated iron and steel foundry, where
                our people are our greatest resource. We believe in excellence!
              </p>
              <br />
            </div>
          </div>
          <div className="">
            <JobListingsWrapper jobs={jobs} />
          </div>
        </div>
      </div>
    </AuthProvider>
  );
};

export default JobsWrapper;

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import M from 'materialize-css';

import { API_URL } from '../../config';
import { AuthContext } from '../../context/auth-provider';

export const AdminBar = ({ setPage, page }) => {
  const { user, logout } = useContext(AuthContext);

  const height = {
    height: '45px',
    lineHeight: '45px',
    paddingTop: 0,
    paddingBottom: 0,
  };

  const buttonLink = {
    background: 'transparent',
    border: 0,
    color: 'white',
    cursor: 'pointer',
    ...height,
    padding: '0 20px',
  };

  const activeButtonLink = {
    background: 'transparent',
    border: 0,
    color: '#04AA6D',
    cursor: 'pointer',
    ...height,
    padding: '0 20px',
  };

  return (
    <nav className="grey darken-2">
      <div className="nav-wrapper">
        <ul style={{ paddingLeft: '10px' }}>
          <li style={height}>ADMIN</li>
        </ul>
        <ul className="right hide-on-med-and-down">
          {!user && (
            <>
              <li>
                <button
                  style={page === 'login' ? activeButtonLink : buttonLink}
                  href="javascript:void()"
                  onClick={() => setPage('login')}
                >
                  Login
                </button>
              </li>
            </>
          )}
          {user && (
            <>
              <li>
                <button
                  style={page === 'new' ? activeButtonLink : buttonLink}
                  href="javascript:void()"
                  onClick={() => setPage('new')}
                >
                  New
                </button>
              </li>
              <li>
                <button
                  style={page === 'list' ? activeButtonLink : buttonLink}
                  href="javascript:void()"
                  onClick={() => setPage('list')}
                >
                  List
                </button>
              </li>
              <li>
                <button
                  style={buttonLink}
                  href="javascript:void()"
                  onClick={logout}
                >
                  Logout
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export const AdminList = ({ jobs, setJob, setJobs }) => {
  const { token } = useContext(AuthContext);

  const handleDelete = (id) => {
    const doDelete = async () => {
      const options = {
        url: `${API_URL}jobs.cfc?method=deleteJob`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${btoa(token)}`,
        },
        data: { id },
      };

      axios(options)
        .then((r) => r.status === 200 && r.data)
        .then((d) => {
          if (d.success) {
            setJobs(jobs.filter((el) => el.id !== id));
            M.toast({ html: 'Job deleted' });
          }
        })
        .catch((e) => console.error(e));
    };

    doDelete();
  };

  return (
    <div className="row">
      <div className="col s12 m8 offset-m2">
        <ul className="collection">
          {jobs.length === 0 && <p>No Jobs Available</p>}
          {jobs.map((el, i) => (
            <li className="collection-item" key={i}>
              <i
                onClick={() => setJob(el)}
                style={{ cursor: 'pointer' }}
                className="green-text material-icons left"
              >
                edit
              </i>
              <span className="black-text">{el.title}</span>
              <i
                onClick={() => handleDelete(el.id)}
                style={{ cursor: 'pointer' }}
                className="red-text material-icons right"
              >
                delete
              </i>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const AdminLogin = ({ setPage }) => {
  const { login, user } = useContext(AuthContext);
  const { register, handleSubmit } = useForm();

  const onSubmit = (values) => {
    login(values);
  };

  user && setPage('list');

  return (
    <>
      <div className="container login-form">
        <div className="row">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="col s12 m6 offset-m3"
            method="post"
          >
            <label htmlFor="username">
              <b>Username</b>
              <input
                type="text"
                placeholder="Enter Username"
                name="username"
                ref={register({ required: 'username is required' })}
              />
            </label>

            <label htmlFor="password">
              <b>Password</b>
              <input
                type="password"
                placeholder="Enter Password"
                name="password"
                ref={register({ required: 'password is required' })}
              />
            </label>

            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    </>
  );
};

export const AdminEdit = ({ job, jobs, setJobs, setPage }) => {
  const { token } = useContext(AuthContext);
  const { register, handleSubmit, control } = useForm(
    job && {
      defaultValues: {
        ...job,
        requirement: job.requirements.map((x) => x.description),
      },
    }
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requirement',
  });

  const onSubmit = (values) => {
    const data = {
      ...job,
      ...values,
      description: values.description.replace(/\r\n|\r|\n/g, '<br />'),
      requirements: fields.map((e) => e.value),
    };

    const doPost = async () => {
      const options = {
        url: `${API_URL}jobs.cfc?method=saveJob`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${btoa(token)}`,
        },
        data,
      };

      const j = await axios(options)
        .then((r) => r.status === 200 && r.data)
        .then((d) => d.success && d.data)
        .catch((e) => console.error(e));

      j.new && setJobs((pJobs) => [...pJobs, j]);
      !j.new && setJobs(jobs.map((e) => (e.id === j.id ? { ...e, ...j } : e)));
      setPage('list');
    };

    doPost();
  };

  const handleNewRequirement = (e) => {
    if (e.target.value.length > 0) {
      append({ value: e.target.value });
      e.target.value = '';
    }
  };

  const handleDeleteRequirement = (i) => {
    remove(i);
  };

  return (
    <>
      <div className="container login-form">
        <div className="row">
          <center>{job && job.id ? 'Editing' : 'New'}</center>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="col s12 m8 offset-m2"
            method="post"
          >
            <label htmlFor="title">
              <b>Title</b>
              <input
                type="text"
                placeholder="Enter Job Title"
                name="title"
                ref={register({ required: 'title is required' })}
              />
            </label>

            <label htmlFor="description">
              <b>Description</b>
              <textarea
                placeholder="Enter Job Description"
                name="description"
                contentEditable
                suppressContentEditableWarning
                ref={register({ required: 'description is required' })}
              ></textarea>
            </label>

            <label htmlFor="active">
              <b>Active</b>
              <select
                name="active"
                className="browser-default"
                ref={register({ required: 'active is required' })}
              >
                <option value="1">YES</option>
                <option value="0">NO</option>
              </select>
            </label>

            <fieldset>
              <label htmlFor={`new_requirement`}>
                <b>New Requirement</b>
                <input
                  type="text"
                  placeholder="Enter Requirement"
                  name={`new_requirement`}
                  onBlur={handleNewRequirement}
                />
              </label>
              <button type="button">ADD</button>
              <hr />
              {fields &&
                fields.map((el, i) => (
                  <label
                    style={{ position: 'relative', display: 'block' }}
                    htmlFor={`requirements[${i}]`}
                    key={el.id}
                  >
                    <b>
                      Requirement
                      <span
                        onClick={() => handleDeleteRequirement(i)}
                        className="red-text"
                        style={{
                          position: 'absolute',
                          right: 0,
                          padding: '5px 10px 0',
                          cursor: 'pointer',
                        }}
                      >
                        DELETE
                      </span>
                    </b>
                    <input
                      type="text"
                      placeholder="Enter Requirement"
                      name={`requirement[${i}].value`}
                      control={control}
                      // ref={register(`requirement.${i}.value`)}
                      defaultValue={fields[i].value}
                    />
                  </label>
                ))}
            </fieldset>

            <button type="submit">Save</button>
          </form>
        </div>
      </div>
    </>
  );
};

export const AdminArea = ({ jobs, setJobs }) => {
  const [page, setPage] = useState('login');
  const [job, setJob] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (job) job.description = job.description.replace(/<br\s?\/?>/g, '\n');
    job && setPage('edit');
    // job && history.replace({ pathname: '/Jobs/admin/edit' });
  }, [job]);

  useEffect(() => {
    history.push({ pathname: `/Jobs/admin/${page}` });
  }, [page]);

  useEffect(() => {
    history.listen(({ pathname }, action) => {
      console.log({ pathname });
      console.log({ action });
      action === 'POP' && setPage(pathname.split('/')[3]);
    });
  }, [page]);

  return (
    <>
      <div className="row">
        <div className="col s12">
          <AdminBar setPage={setPage} page={page} />
        </div>
      </div>
      {page === 'list' && (
        <AdminList setJobs={setJobs} setJob={setJob} jobs={jobs} />
      )}
      {page === 'edit' && (
        <AdminEdit job={job} jobs={jobs} setPage={setPage} setJobs={setJobs} />
      )}
      {page === 'new' && (
        <AdminEdit jobs={jobs} setPage={setPage} setJobs={setJobs} />
      )}
      {page === 'login' && <AdminLogin setPage={setPage} />}
      <h5>
        <center>Preview Job Listings Below</center>
      </h5>
    </>
  );
};

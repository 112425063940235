import React, { useEffect } from 'react';
import { appendScript, removeScript } from '../../utils/scripts';
import AlloysImg from '../../images/alloy-castings.jpg';

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div>
      <div
        className="themeTextColor"
        style={{ backgroundColor: '#3d3d3d', paddingBottom: '50px' }}
      >
        <div className="row container ">
          <div class="flexAdj">
            <div className="col s12 l4">
              <img src={AlloysImg} alt="" style={{ width: '100%' }} />
            </div>
            <div className="col s12 l8 z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0', textTransform: 'uppercase' }}
              >
                Our Alloy Castings
              </h1>
              <p>Talk to us about your next ferrous castings.</p>

              <p>
                Replacement Metal Castings prides itself on being a one stop
                shop. We can handle all of your Iron, Steel and Stainless
                casting needs to include in house heat treat.
              </p>
            </div>
          </div>

          <div className="clearfix"></div>

          <div class="tableSameHeight" style={{ paddingTop: '50px' }}>
            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0', textTransform: 'uppercase' }}
              >
                STEEL
              </h1>
              <ul className="discStyle">
                <li>Carbon Steels</li>
                <li>ASTM A27 (1020, 1030, 1045)</li>
                <li>ASTM A216 (WCA, WCB, WCC)</li>
              </ul>

              <ul className="discStyle">
                <li>Alloy Steels</li>
                <li>ASTM A148 (8600, 4100, 4300)</li>
                <li>High Wear Steels (Mine Grade)</li>
                <li>Hadfield Manganese</li>
              </ul>

              <ul className="discStyle">
                <li>Heat Resistant</li>
                <li>ASTM A297 (H series)</li>
              </ul>
            </div>

            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0', textTransform: 'uppercase' }}
              >
                STAINLESS
              </h1>
              <ul className="discStyle">
                <li>Corrosion Resistant</li>
                <li>ASTM A351 (CF8, CF8M, CF3, CF3M)</li>
                <li>ASTM A890 (CD4MCu, CD3Mn)</li>
              </ul>
            </div>
            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0', textTransform: 'uppercase' }}
              >
                IRON
              </h1>
              <ul className="discStyle">
                <li>Grey Iron</li>
                <li>ASTM A278 (CL 30)</li>
                <li>ASTM A216 (WCA, WCB, WCC)</li>
              </ul>

              <ul className="discStyle">
                <li>Ductile Iron</li>
                <li>ASTM A536 (65-45-12 / 80-55-06 / 100-70-03)</li>
              </ul>

              <ul className="discStyle">
                <li>Abrasion Resistant</li>
                <li>ASTM A532 (CL1, 2 & 3)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

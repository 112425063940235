import React, { useEffect } from 'react';
// import { Link } from "react-router-dom";
import { appendScript, removeScript } from '../../utils/scripts';
import CastingImg from '../../images/metal-alloys-02.jpg';

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div>
      <div
        className="themeTextColor"
        style={{ backgroundColor: '#3d3d3d', paddingBottom: '50px' }}
      >
        <div className="row container ">
          <div class="flexAdj">
            <div className="col s12 l4">
              <img src={CastingImg} alt="" style={{ width: '100%' }} />
            </div>
            <div className="col s12 l8 z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0', textTransform: 'uppercase' }}
              >
                Industries Served
              </h1>
              <p>
                Replacement Metal Castings does things the way they should be
                done.
              </p>

              <p>
                Replacement Metal Castings is distinguished by the industries we
                serve.
              </p>

              <p>
                Our high degree of diversification in metallurgies and
                familiarity and experience with a wide range of materials
                enables us to fluently speak the languages of all those
                industries. In addition to completing projects, we are able to
                make suggestions to improve performance. While we may not be
                engineering a part, we can talk expertly about diverse materials
                and have educated discussions about your business based on our
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

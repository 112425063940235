import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { appendScript, removeScript } from '../../utils/scripts';
// import cta01 from "../../images/RMC-cta-001.jpg";
// import cta02 from "../../images/RMC-cta-002.jpg";
// import cta03 from "../../images/RMC-cta-003.jpg";

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div>
      <div
        className="themeTextColor"
        style={{ backgroundColor: '#3d3d3d', padding: '50px 0' }}
      >
        <div className="row container ">
          <div class="tableSameHeight">
            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0' }}
              >
                Our Molding Department
              </h1>
              <ul className="discStyleNoChild">
                <li>
                  Over 200' of conveyor space for production mounted tooling
                </li>
                <li>550# Tinker Omega TOM350 Articulated Arm Mixer</li>
                <li>300# Palmer CAM3 Mixer</li>
                <li>50# Palmer Mixer</li>
                <li>2-part chemically bonded sand system (air set)</li>
                <li>Three crane mounted mold handlers</li>
                <li>Spray Wash System</li>
                <li>Flow Coat System</li>
                <li>Floor space for larger, loose tooling </li>
              </ul>
            </div>

            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0' }}
              >
                Our Melting Department
              </h1>
              <ul className="discStyleNoChild">
                <li>750kW Inductotherm Power Supply</li>
                <li>600kW Ajax Power Supply</li>
                <li>(2) 2000# Furnaces</li>
                <li>(2) 1500#Furnace</li>
                <li>Spectromax Spectrometer for Metallurgical Control</li>
                <li>Pyrometers</li>
                <li>Gas fired ladle pre-heater</li>
                <li>Multiple teapot style ladles </li>
              </ul>
            </div>
            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0' }}
              >
                Our Cleaning Department
              </h1>
              <ul className="discStyleNoChild">
                <li>
                  Multiple Swing and Pedestal grinders ranging from 14" to 30"
                </li>
                <li>2 Wheelabrator Shot Blast Table</li>
                <li>Wheelabrator TumBlast</li>
                <li>
                  Hand grinding stations equipped with vertical grinders, air
                  chipping hammers, and die grinders
                </li>
                <li>Multiple welding machines</li>
                <li>Air Arc Equipment</li>
                <li>Oxy Acetylene Torches </li>
              </ul>
            </div>
          </div>

          <div class="tableSameHeight">
            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0' }}
              >
                Full Thermal Sand Reclamation System
              </h1>
              <ul className="discStyleNoChild">
                <li>Gudgeon International VS-12 Shakeout Deck</li>
                <li>Thermfire 2000 Thermal Sand Reclaimer</li>
                <li>Magnetic Particle Separator</li>
                <li>Multiple sand transporters </li>
              </ul>
            </div>

            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0' }}
              >
                Our Services
              </h1>
              <ul className="discStyleNoChild">
                <li>Full service pattern shop</li>
                <li>Heat Treatment, including water quenching facilities</li>
                <li>Bell style batch oven capable of processing 5000#/load</li>
                <li>Reverse Engineering</li>
                <li>Custom or proprietary metallurgies upon request</li>
              </ul>
            </div>
            <div className="tableCellSameHeight z-depth-5 themeBgColor blockPaddingAdj center valign-wrapper hide-on-med-and-down">
              <p style={{ margin: '0 auto' }}>
                LEARN MORE
                <br />
                <Link className="btn-large" to="/Metal-Alloy-Castings">
                  OUR ALLOY CASTINGS
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

import React, { createContext, useReducer, useEffect } from 'react';
import { createReducer, initialState } from './AuthReducer';
import { API_URL } from '../../config';
import Axios from 'axios';
import { setWithExpiry } from '../tokens';
import { CONST_USER, CONST_TOKEN } from './keys';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const memorizedReducer = useCallback(() => createReducer(), []);
  const [state, dispatch] = useReducer(createReducer(), initialState);

  useEffect(() => {
    setWithExpiry(CONST_USER, state.user ? state.user : null);
    setWithExpiry(
      CONST_TOKEN,
      state.token && state.token.length > 0 ? state.token : ''
    );
  }, [state.user, state.token]);

  const login = async (payload) => {
    try {
      dispatch({ type: 'REQUEST_LOGIN' });

      return Axios({
        url: `${API_URL}users.cfc?method=login`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: btoa(`${payload.username}:${payload.password}`),
        },
      })
        .then((r) => r.status === 200 && r.data)
        .then((payload) => {
          console.log({ payload }, 'axios return');
          if (payload.user && payload.token) {
            dispatch({ type: 'LOGIN_SUCCESS', payload });
            return payload;
          }
        })
        .catch((error) => {
          dispatch({ type: 'LOGIN_ERROR', error });
          console.log(error);
        });
    } catch (error) {
      dispatch({ type: 'LOGIN_ERROR', error });
      console.log(error);
    }
  };

  const logout = (payload) => {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem(CONST_USER);
    localStorage.removeItem(CONST_TOKEN);
  };

  const contextValues = {
    login,
    logout,
    ...state,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

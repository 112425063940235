import React from 'react';

const DefaultLayout = (props) => {
	return (
		<div id="wrapper">
			<div id="content">{props.children}</div>
		</div>
	);
};

export default DefaultLayout;

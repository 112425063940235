let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
// export const API_URL = arr2[0].indexOf('joes.com') > 0 ? arr[0] : 'http:' + '//' + arr2[0] + '/cfc/api/';
export const API_URL = arr[0] + '//' + arr2[0] + '/cfc/api/';
export const HOME_URL = 'https://bahamajoes.com/';
export const WEBJAGUAR_URL = 'https://shop.bahamajoes.com/';

export default {
  apiBaseUrl:
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname === '192.168.0.110'
      ? `http://${window.location.hostname}/cfc/api/`
      : 'http://petsatpeacesav-com.janus-thesabresolution-com.vps.ezhostingserver.com/cfc/api/',
};

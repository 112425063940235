import React from 'react';
import { Helmet } from 'react-helmet';
import Route, { HomeRoute } from './routes';
import Favicon from 'react-favicon';
import FaviconImg from './images/favicon.ico';
import HomePage from './containers/HomePage';
import AboutUsPage from './containers/AboutUsPage';
import AlloysPage from './containers/AlloysPage';
import CapabilitiesPage from './containers/CapabilitiesPage';
import IndustriesServedPage from './containers/IndustriesServedPage';
import ContactPage from './containers/ContactPage';
import JobsPage from './containers/JobsPage';
import ApplyPage from './containers/ApplyPage';

import NotFoundPage from './containers/NotFoundPage';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ScrollToTop from './utils/ScrollToTop';

const { REACT_APP_RECAPTCHA_KEY } = process.env;

const gRecaptchaVars = {
  reCaptchaKey: REACT_APP_RECAPTCHA_KEY,
};

const App = () => {
  return (
    <GoogleReCaptchaProvider {...gRecaptchaVars}>
      <Router>
        <Favicon url={FaviconImg} />
        <ScrollToTop />
        <React.Fragment>
          <Switch>
            <HomeRoute
              exact
              path="/"
              component={() => {
                return (
                  <>
                    <Helmet>
                      <title>Replacement Metal Castings</title>
                      <meta
                        name="description"
                        content="We are a full service foundry who pours metal castings, alloy castings, and has provided metal resoration services for 40+ years."
                      />
                      <meta
                        name="keywords"
                        content="replacement sheet metal, reverse engineering services, metal castings replacement, metal castings aggregate, processing metal castings company, metal resoration, metal molding, dredging services, replacement textile parts, metallurgical services, metal foundry, metal melting company, CAD drawings, metal castings, aluminum castings, metal alloys, aluminum alloys, alloy castings"
                      />
                    </Helmet>
                    <HomePage />
                  </>
                );
              }}
            />
            <HomeRoute
              exact
              path="/About"
              component={(p) => {
                return (
                  <>
                    <Helmet>
                      <title>About our Foundry</title>
                    </Helmet>
                    <AboutUsPage {...p} />
                  </>
                );
              }}
            />
            <HomeRoute
              exact
              path="/jobs"
              component={(p) => {
                return (
                  <>
                    <Helmet>
                      <title>Jobs at Replacement Metal Castings</title>
                    </Helmet>
                    <JobsPage {...p} />
                  </>
                );
              }}
            />
            <HomeRoute
              exact
              path="/apply/:id"
              component={(p) => {
                return (
                  <>
                    <Helmet>
                      <title>Jobs at Replacement Metal Castings</title>
                    </Helmet>
                    <ApplyPage {...p} />
                  </>
                );
              }}
            />
            <HomeRoute
              path="/jobs"
              component={(p) => {
                return (
                  <>
                    <Helmet>
                      <title>Jobs at Replacement Metal Castings</title>
                    </Helmet>
                    <JobsPage {...p} />
                  </>
                );
              }}
            />
            <HomeRoute
              exact
              path="/Metal-Alloy-Castings"
              component={(p) => {
                return (
                  <>
                    <Helmet>
                      <title>Our Metal Alloys</title>
                    </Helmet>
                    <AlloysPage {...p} />
                  </>
                );
              }}
            />
            <HomeRoute
              exact
              path="/Metal-Foundry-Services"
              component={(p) => {
                return (
                  <>
                    <Helmet>
                      <title>Our Metal Casting Services</title>
                    </Helmet>
                    <CapabilitiesPage {...p} />
                  </>
                );
              }}
            />
            <HomeRoute
              exact
              path="/Metallurgical-Services"
              component={(p) => {
                return (
                  <>
                    <Helmet>
                      <title>Metal Foundry Needs</title>
                    </Helmet>
                    <IndustriesServedPage {...p} />
                  </>
                );
              }}
            />
            <HomeRoute
              exact
              path="/Contact"
              component={(p) => {
                return (
                  <>
                    <Helmet>
                      <title>
                        Paradigm Four – Crisis Management Consulting
                      </title>
                    </Helmet>
                    <ContactPage {...p} />
                  </>
                );
              }}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </React.Fragment>
      </Router>
    </GoogleReCaptchaProvider>
  );
};

export default App;

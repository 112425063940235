import React, { useEffect } from 'react';
import { appendScript, removeScript } from '../../utils/scripts';
import AboutImg01 from '../../images/metal-alloys-01.jpg';
import AboutImg02 from '../../images/RMC-capabilities.jpg';

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div>
      <div
        className="themeTextColor"
        style={{ backgroundColor: '#3d3d3d', paddingBottom: '50px' }}
      >
        <div className="row container ">
          <div class="flexAdj">
            <div className="col s12 l4">
              <img src={AboutImg01} alt="" style={{ width: '100%' }} />
            </div>
            <div className="col s12 l8 z-depth-5 themeBgColor blockPaddingAdj">
              <h1
                className="abstractTextColor robotoSlab"
                style={{ margin: '0', textTransform: 'uppercase' }}
              >
                Built to last and grow
              </h1>
              <p>
                Replacement Metal Castings is a third-generation family owned
                business started in 1979 as a pattern shop servicing the foundry
                industry. As the company grew and matured we intentionally
                diversified our business model to gain more experience and
                strengthen our ability to be a resource for our Customers in
                various industries.
              </p>

              <p>
                Replacement Metal Castings is increasingly unique in our
                industry. We’re who you contact when you need small production
                runs – we can handle one, two or 10 just as easily as an order
                for 100 pieces. While large steel facilities typically cannot
                accommodate small quantity orders, Replacement Metal Castings
                strives to fill that market.
              </p>
            </div>
          </div>

          <div class="flexAdj">
            <div className="col s12 l4">
              <img src={AboutImg02} alt="" style={{ width: '100%' }} />
            </div>
            <div className="col s12 l8 z-depth-5 themeBgColor blockPaddingAdj">
              <p>
                We are a full-service jobbing foundry; you can come to us when
                your original manufacturer is no longer in business or you need
                parts to support your legacy equipment. While other foundries
                have been forced to close, we’ve thrived and planned for the
                future by constantly improving our process and strategically
                reinvesting in our business. And we’re still growing, with plans
                on the board to add another 20,000 square feet of manufacturing
                space.
              </p>

              <p>
                Unlike other foundries, Replacement Metal Castings intentionally
                seeks to work with a highly diversified mix of industries. At
                any given time, we are working on projects in half a dozen
                industries, expanding our knowledge base and refining our
                systems. We’ve built the company to last and sustain itself, and
                Replacement Metal Castings isn’t going anywhere but forward in
                the future.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

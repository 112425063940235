import React from 'react';
import M from 'materialize-css';
import Form from './form';

// const topStyle = {};

export default class Home extends React.Component {
  componentDidMount() {
    M.Sidenav.init(document.getElementById('mobile-nav'));
  }

  render() {
    return (
      <div className="themeBgColor" id="Contact">
        <div className="container themeTextColor">
          <footer className="row" style={{ margin: '0' }}>
            {/* <ul className="hide-on-med-and-down">
							<li>
								<Link className="" to="/About-Us">
									About Us
								</Link>
							</li>
							<li>
								<Link className="" to="/Why-Choose-Us">
									Why Choose Us
								</Link>
							</li>
							<li>
								<Link className="" to="/Our-Services">
									Our Services
								</Link>
							</li>
							<li>
								<Link className="" to="/Pet-Tales">
									Pet Tales
								</Link>
							</li>
							<li>
								<Link className="" to="/FAQs">
									FAQs
								</Link>
							</li>
							<li>
								<Link className="" to="/Contact-Us">
									Contact Us
								</Link>
							</li>
							<li className="ssIcon white-text">
								<a
									href="https://www.facebook.com/foxandweekspetsatpeace"
									target="_blank"
									rel="noopener noreferrer"
									className="fbIcon white-text"
								>
									<i className="fab fa-facebook-f"></i>
								</a>
							</li>
						</ul> */}

            <div className="col s12 m6 l5 offset-l1 center">
              <br />
              <div className="clearfix"></div>
              <Form />
            </div>
            <div className="col s12 m6 l5">
              <div className="contactInfoAdj">
                <p>
                  <span className="abstractTextColor robotoSlab">
                    General Information
                  </span>
                  <br />
                  <span style={{ fontSize: '18px' }}>
                    PHONE: 843-784-2251
                    <br />
                    Fax: 843-784-3627
                    <br />
                    General email:
                    <br />
                    <a
                      href="mailto:info@CarolinaMetalCastings.com"
                      className="themeTextColor"
                    >
                      info@CarolinaMetalCastings.com
                    </a>
                  </span>
                </p>
                <p>
                  <span className="abstractTextColor robotoSlab">
                    OFFICE AND FOUNDRY LOCATION
                  </span>
                  <br />
                  <span style={{ fontSize: '18px' }}>
                    354 Industrial Park Road
                    <br />
                    Hardeeville, SC 29927
                  </span>
                </p>
                <p>
                  <span className="abstractTextColor robotoSlab">
                    MAILING ADDRESS
                  </span>
                  <br />
                  <span style={{ fontSize: '18px' }}>
                    PO Box 95
                    <br />
                    Hardeeville, SC 29927
                  </span>
                </p>
                <p>
                  <span className="abstractTextColor robotoSlab">
                    <a
                      href="https://goo.gl/maps/osrLDJTbvqPmnFZ48"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      DRIVING DIRECTIONS
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div className="clearfix"></div>
            <p
              style={{
                fontSize: '14px',
                marginTop: '25px',
                textAlign: 'center',
              }}
            >
              &copy; 2021 Replacement Metal Castings | Crafted and Hosted by{' '}
              <a
                href="https://thesabresolution.com/"
                rel="noopener noreferrer"
                target="_blank"
                className="white-text"
              >
                The Sabre Solution
              </a>
            </p>
          </footer>
        </div>
      </div>
    );
  }
}

import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import redLine from '../../images/RMC-hero.jpg';
import cta01 from '../../images/RMC-cta-001.jpg';
import cta02 from '../../images/RMC-cta-002.jpg';
import cta03 from '../../images/RMC-cta-003.jpg';
import { appendScript, removeScript } from '../../utils/scripts';

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const getHeroFontSize = (size) => {
  console.log(size.width);
  if(size.width > 989) return {
    span: {
      left: `${20}%`,
      top: `${29}%`,
      width: `${33}%`
    },
    h1: {
      fontSize:`${44}px`,
      fontWeight:"bold",
      textShadow:'2px 2px black'
    }
  };

  if(size.width > 741) return {
    span: {
      left: `${20}%`,
      top: `${34}%`,
      width: `${33}%`
    },
    h1: {
      fontSize:`${28}px`,
      fontWeight:"bold",
      textShadow:'2px 2px black'
    }
  };

  if(size.width > 560) return {
    span: {
      left: `${13}%`,
      top: `${22}%`,
      width: `${33}%`
    },
    h1: {
      fontSize:`${28}px`,
      fontWeight:"bold",
      textShadow:'2px 2px black'
    }
  };

  return {
    span: {
      left: `${13}%`,
      top: `${22}%`,
      width: `${33}%`
    },
    h1: {
      fontSize:`${19}px`,
      fontWeight:"bold",
      textShadow:'2px 2px black'
    }
  };
}

const HomePage = () => {
  const s = getHeroFontSize(useWindowSize());

  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div className="">
      <div style={{ display: 'flex',position:'relative' }}>
        <img src={redLine} style={{ width: '100%' }} alt="" />
        <span style={{position:'absolute',...s.span}}>
        <h1
              className="abstractTextColor robotoSlab"
              style={{ ...s.h1,margin: '0', color:'white',textTransform: 'uppercase' }}
            >
              Molding America one part at time
            </h1>

        </span>
      </div>
      <div
        className="themeTextColor"
        style={{
          paddingBottom: '70px',
          position: 'relative',
          backgroundColor: '#3d3d3d',
        }}
      >
        <div className="row container center">
          <div
            className="col s12 l10 offset-l1 z-depth-5 themeBgColor"
            style={{ paddingBottom: '15px' }}
          >
            <h1
              className="abstractTextColor robotoSlab"
              style={{ margin: '0', textTransform: 'uppercase' }}
            >
              We are a full service casting facility.
            </h1>
            <p className="content800">
              Since 1979, Replacement Metal Castings has specialized in the
              production of industrial aftermarket parts. We are 100% made in
              the USA and we service a wide range of industries, repair
              facilities & original equipment manufacturers.
            </p>
            <p
              className="content800 robotoSlab"
              style={{ fontSize: '30px', paddingBottom: '30px' }}
            >
              1 part or 1000, give us a call on your next project.
            </p>
            <div class="ctaBoxAdj">
              <div className="col s12 m4 l4 ">
                <img src={cta01} alt="" style={{ width: '100%' }} />
              </div>
              <div className="col s12 m4 l4 ">
                <img src={cta02} alt="" style={{ width: '100%' }} />
              </div>
              <div className="col s12 m4 l4 ">
                <img src={cta03} alt="" style={{ width: '100%' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>

      <div className="clearfix"></div>
    </div>
  );
};

export default HomePage;

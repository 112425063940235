import React from "react";
import { NavLink } from "react-router-dom";

function navlinks({ closeMobileNav, ...rest }) {
	return (
		<React.Fragment>
			{/* <NavLink to="/faq" activeClassName="selected">
				FAQs
			</NavLink> */}
			<li>
				<NavLink
					className="hvr-underline-from-left"
					activeClassName="selected"
					to="/About"
					onClick={() => closeMobileNav()}
				>
					ABOUT
				</NavLink>
			</li>

			<li>
				<NavLink
					className="hvr-underline-from-left"
					activeClassName="selected"
					to="/Metal-Alloy-Castings"
					onClick={() => closeMobileNav()}
				>
					ALLOYS
				</NavLink>
			</li>

			<li>
				<NavLink
					className="hvr-underline-from-left"
					activeClassName="selected"
					to="/Metal-Foundry-Services"
					onClick={() => closeMobileNav()}
				>
					CAPABILITIES
				</NavLink>
			</li>

			<li>
				<NavLink
					className="hvr-underline-from-left"
					activeClassName="selected"
					to="/Metallurgical-Services"
					onClick={() => closeMobileNav()}
				>
					INDUSTRIES SERVED
				</NavLink>
			</li>

			<li>
				<NavLink
					className="hvr-underline-from-left"
					activeClassName="selected"
					to="/Jobs"
					onClick={() => closeMobileNav()}
				>
					JOBS
				</NavLink>
			</li>

			<li>
				<a
					className="hvr-underline-from-left"
					activeClassName="selected"
					href="#Contact"
					onClick={() => closeMobileNav()}
				>
					CONTACT
				</a>
			</li>
		</React.Fragment>
	);
}

export default navlinks;

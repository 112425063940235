import { CONST_USER, CONST_TOKEN } from './keys';
import { getWithExpiry } from '../tokens';

export const createReducer = () => AuthReducer;

let user = getWithExpiry(CONST_USER) ? getWithExpiry(CONST_USER) : null;
let token = getWithExpiry(CONST_TOKEN) ? getWithExpiry(CONST_TOKEN) : '';

export const initialState = {
  user: null || user,
  token: null || token,
  loading: false,
  errors: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        user: null,
        token: '',
      };
    case 'LOGIN_ERROR':
      return {
        ...initialState,
        loading: false,
        errors: action.errors,
      };
    default:
      return initialState;
  }
};

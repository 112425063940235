import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { appendScript, removeScript } from "../../utils/scripts";
import { useForm } from "react-hook-form";
import Axios from "axios";
import M from "materialize-css";

let url = window.location.href;
let arr = url.split("/");
let arr2 = arr[2].split(":");
const URL = arr[0] + "//" + arr2[0] + "/cfc/api/";

const form = {
	name: "",
	email: "",
	phone: "",
	message: "",
	file: "",
};

const HomePage = () => {
	const { register, handleSubmit, errors } = useForm({ defaultValues: form });
	const [message, setMessage] = useState(null);
	const [selectedFile, setSelectedFile] = useState();

	useEffect(() => {
		appendScript("/assets/js/css3-animate-it.js");

		return () => removeScript("/assets/js/css3-animate-it.js");
	}, []);

	const inputChangeHandler = (e) => {
		setSelectedFile(e.target.files[0]);
	};

	const sendContactForm = (v, e) => {
		const values = { ...form, ...v };

		const fd = new FormData();

		fd.append("file", selectedFile);

		Axios({
			method: "POST",
			url: `${URL}webmail.cfc?method=upload`,
			data: fd,
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				if (d.success) {
					Axios({
						method: "POST",
						url: `${URL}webmail.cfc?method=email`,
						headers: {
							"Content-Type": "application/json",
						},
						data: { ...values, link: d.link },
					})
						.then((res) => res.status === 200 && res.data)
						.then((data) => {
							if (data.success) {
								e.target.reset();
								M.toast({ html: "Thanks, we will get back to you soon!" });
								setMessage("Thanks, we will get back to you soon!");
							}
						})
						.catch((e) => console.log(e));
				} else {
					d.messages.map((m) => M.toast({ html: m }));
				}
			})
			.catch((e) => {
				console.log({ e });
				M.toast({ html: "Error Uploading your file!" });
			});
	};

	useEffect(() => {
		let timer1 = setTimeout(() => setMessage(""), 8 * 1000);

		// this will clear Timeout
		// when component unmount like in willComponentUnmount
		// and show will not change to true
		return () => {
			clearTimeout(timer1);
		};
	}, [message]);

	return (
		<div className="">
			{/* <div style={{ display: 'flex' }}>
            <img src={redLine} style={{ width: '100%' }} />
         </div> */}
			<div className="abstractTextColor robotoSlab">
				<div className="center">
					<div>
						<h1 style={{ margin: "0" }}>CONTACT US TODAY</h1>
					</div>
				</div>
			</div>
			<div className="clearfix"></div>
			<div className="container">
				<form
					onSubmit={handleSubmit(sendContactForm)}
					className="col s12 m12 l12"
				>
					<div>
						{message && (
							<p
								style={{ padding: "20px", background: "green", color: "white" }}
							>
								{message}
							</p>
						)}
						<div className="input-field col s12 m12 l12">
							<input
								ref={register({ required: "Name is required" })}
								id="name"
								name="name"
								type="text"
								className="validate"
								placeholder="NAME *"
							/>
							{/* <label for="name">*Your name</label> */}
							{errors && errors.name && (
								<span className="helper-text">{errors.name.message}</span>
							)}
						</div>
						<div className="input-field col s12 m12 l12">
							<input
								ref={register({ required: "Email is required" })}
								name="email"
								id="email"
								type="text"
								className="validate"
								placeholder="EMAIL *"
							/>
							{/* <label for="email">*Your email address</label> */}
							{errors && errors.email && (
								<span className="helper-text">{errors.email.message}</span>
							)}
						</div>
						<div className="input-field col s12 m12 l12">
							<input
								ref={register}
								id="phone"
								name="phone"
								type="text"
								className="validate"
								placeholder="PHONE"
							/>
							{/* <label for="phone">Your phone number</label> */}
						</div>
						<div className="input-field col s12">
							<textarea
								name="message"
								ref={register}
								id="message"
								type="text"
								className="materialize-textarea validate"
								placeholder="MESSAGE"
								style={{ paddingTop: "15px", height: "145px" }}
							></textarea>
							{/* <label for="message">Your message</label> */}
						</div>
						<div className="file-field input-field col s12">
							<div className="btn">
								<span>File</span>
								<input name="file" type="file" onChange={inputChangeHandler} />
							</div>
							<div className="file-path-wrapper">
								<input
									ref={register}
									name="filew"
									className="file-path validate"
									type="text"
									placeholder="UPLOAD FILE"
								/>
							</div>
						</div>
						<div className="col s12 m12 l12 center">
							<br />
							<button type="submit" name="submit" className="btn">
								Send Message
							</button>
						</div>
						<div className="clearfix"></div>
					</div>
				</form>
			</div>
			<div className="clearfix"></div>
			<br />
			<div className="row container cursors"></div>
			<div className="clearfix"></div>
			<br />
			<br />
		</div>
	);
};

export default HomePage;

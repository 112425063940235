import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { appendScript, removeScript } from "../../utils/scripts";
import { useForm } from "react-hook-form";
import Axios from "axios";
import M from "materialize-css";

let url = window.location.href;
let arr = url.split("/");
let arr2 = arr[2].split(":");
const URL = arr[0] + "//" + arr2[0] + "/cfc/api/";

const HomePage = () => {
	const form = {
		name: "",
		email: "",
		phone: "",
		message: "",
	};
	const { register, handleSubmit, errors } = useForm({ defaultValues: form });
	useEffect(() => {
		appendScript("/assets/js/css3-animate-it.js");

		return () => removeScript("/assets/js/css3-animate-it.js");
	}, []);
	const [message, setMessage] = useState(null);

	const sendContactForm = (values, e) => {
		const _values = { ...form, ...values };

		Axios({
			method: "POST",
			url: `${URL}webmail.cfc?method=email`,
			headers: {
				"Content-Type": "application/json",
			},
			data: _values,
		})
			.then((res) => res.status === 200 && res.data)
			.then((data) => {
				if (data.success) {
					e.target.reset();
					M.toast({ html: "Thanks, we will get back to you soon!" });
					setMessage("Thanks, we will get back to you soon!");
				}
			})
			.catch((e) => console.log(e));
	};

	useEffect(() => {
		let timer1 = setTimeout(() => setMessage(""), 8 * 1000);

		// this will clear Timeout
		// when component unmount like in willComponentUnmount
		// and show will not change to true
		return () => {
			clearTimeout(timer1);
		};
	}, [message]);

	return (
		<div className="">
			{/* <div style={{ display: 'flex' }}>
            <img src={redLine} style={{ width: '100%' }} />
         </div> */}
			<div
				className="black white-text"
				style={{
					background: "#0a0a0a",
					paddingBottom: "50px",
					position: "relative",
				}}
			>
				<div className="row container center">
					<div>
						<h1 style={{ margin: "0" }}>Contact</h1>
						<p className="content800">
							Your communication with Paradigm Four is secure and completely
							confidential. This site employs EVSSL (Extended Validation
							Certificate), the highest global standard in encryption.
							<br />
							<br />
							Complete the form below. We will contact you within 3 hours.
						</p>
						<div className="clearfix"></div>
						<br />
					</div>
				</div>
			</div>
			<div className="clearfix"></div>
			<br />
			<br />

			<div className="row container">
				<form
					onSubmit={handleSubmit(sendContactForm)}
					className="col s12 m12 l6 offset-l3"
				>
					<div
						style={{
							border: "1px solid rgb(221, 221, 221)",
							borderRadius: "10px",
							padding: "15px 10px 20px",
						}}
					>
						{message && (
							<p
								style={{ padding: "20px", background: "green", color: "white" }}
							>
								{message}
							</p>
						)}
						<div
							className="input-field col s12 m12 l12"
							style={{ marginBottom: "0" }}
						>
							<input
								ref={register({ required: "Name is required" })}
								id="name"
								name="name"
								type="text"
								className="validate"
								placeholder="* Your name"
							/>
							{/* <label for="name">*Your name</label> */}
							<span className="helper-text">
								{errors && errors.name && errors.name.message}
							</span>
						</div>
						<div
							className="input-field col s12 m12 l12"
							style={{ marginBottom: "0" }}
						>
							<input
								ref={register({ required: "Email is required" })}
								name="email"
								id="email"
								type="text"
								className="validate"
								placeholder="* Your email"
							/>
							{/* <label for="email">*Your email address</label> */}
							<span className="helper-text">
								{errors && errors.email && errors.email.message}
							</span>
						</div>
						<div
							className="input-field col s12 m12 l12"
							style={{ marginBottom: "0" }}
						>
							<input
								ref={register}
								id="phone"
								name="phone"
								type="text"
								className="validate"
								placeholder="Your phone"
							/>
							{/* <label for="phone">Your phone number</label> */}
						</div>
						<div className="input-field col s12">
							<textarea
								name="message"
								ref={register}
								id="message"
								type="text"
								className="materialize-textarea validate"
								placeholder="Your message"
								style={{ paddingTop: "15px", height: "100px" }}
							></textarea>
							{/* <label for="message">Your message</label> */}
						</div>
						<div className="col s12 m12 l12 center">
							<button type="submit" name="submit" className="btn">
								Send Message
							</button>
						</div>
						<div className="clearfix"></div>
					</div>
				</form>
			</div>

			<div className="clearfix"></div>
			<br />
			<div className="row container cursors"></div>

			<div className="clearfix"></div>
			<br />
			<br />
		</div>
	);
};

export default HomePage;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import M from 'materialize-css';
import { AuthProvider } from '../../context/auth-provider';
import { AdminArea } from './admin';
import { API_URL } from '../../config';
import JobApplication from './application';

import './style.scss';

const JobIndexItem = ({ job, setJob }) => {
  useEffect(() => {
    const elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {
      accordion: false,
      onOpenStart: function (e) {
        e.querySelector('.material-icons').innerHTML = 'remove';
      },
      onCloseStart: function (e) {
        e.querySelector('.material-icons').innerHTML = 'add';
      },
    });
  }, []);
  return (
    <div style={{ cursor: 'pointer' }} className="col s12 m6 l4">
      <ul className="collapsible z-depth-5 themeBgColor">
        <li className="">
          <div className="collapsible-header themeBgColor abstractTextColor">
            <h1 className="jobTitle robotoSlab" style={{ margin: '0' }}>
              <div>{job.title}</div>
              <div>
                <i className="material-icons" style={{ margin: '0' }}>
                  add
                </i>
              </div>
            </h1>
            <p className="themeTextColor">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos modi
              ab quibusdam aliquam officiis ea veritatis atque ad! Velit
              recusandae.
            </p>
          </div>
          <div className="collapsible-body">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id iusto
              maiores sint aspernatur libero voluptas numquam temporibus non,
              quod asperiores optio nisi, odit ducimus dolore. Sint tenetur
              laborum praesentium nihil?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. In eius
              explicabo vero nihil nemo similique nisi quia, excepturi eum
              consequuntur cumque, veniam blanditiis cum incidunt iste assumenda
              beatae doloribus ad?
            </p>
            <ul>
              <li>Distinctio praesentium voluptatem vitae sapiente!</li>
              <li>
                Neque dolore vel esse quibusdam, quas quasi cum laudantium
                dolor?
              </li>
              <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
            </ul>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Non nam
              iste sapiente sed impedit laborum at, culpa quidem eaque porro
              doloremque voluptas numquam ex, natus distinctio velit error.
              Velit, accusantium!
            </p>
            <p>
              <a onClick={() => setJob(job)} className="btn">
                Apply now
              </a>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

const JobListings = ({ jobs, setJob }) => {
  return (
    <>
      {jobs.length === 0 && (
        <h1 className="abstractTextColor robotoSlab" style={{ marginTop: '0' }}>
          No Jobs Available
        </h1>
      )}
      {jobs.map((el, i) => (
        <JobIndexItem key={i} job={el} setJob={setJob} />
      ))}
    </>
  );
};

const JobApplyWrapper = ({ job }) => {
  useEffect(() => {
    const top = document.getElementById('app_form').top;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }, 200);
  }, []);

  return <JobApplication job={job} />;
};

const JobsWrapper = ({ match, location, ...rest }) => {
  const showAdmin = location.pathname.indexOf('/admin') > -1;

  const {
    params: { id: jid },
  } = match;

  const [job, setJob] = useState(null);

  useEffect(() => {
    const get = async () => {
      axios
        .get(`${API_URL}jobs.cfc?method=getJobs`)
        .then((r) => r.status === 200 && r.data)
        .then((d) => d.filter((e) => e.id === parseInt(jid)))
        .then((d) => d.length && setJob(d[0]))
        .catch((e) => new Error('error'));
    };

    get();
  }, []);

  return (
    <AuthProvider>
      <div
        className="themeTextColor"
        style={{ backgroundColor: '#3d3d3d', paddingBottom: '50px' }}
      >
        <div className="container row">
          <div className="jobStyle">
            <JobApplyWrapper job={job} />
          </div>
        </div>
      </div>
    </AuthProvider>
  );
};

export default JobsWrapper;
